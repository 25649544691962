exports.components = {
  "component---src-pages-3-d-прогулка-jsx": () => import("./../../../src/pages/3d-прогулка.jsx" /* webpackChunkName: "component---src-pages-3-d-прогулка-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-все-о-садике-index-jsx": () => import("./../../../src/pages/все-о-садике/index.jsx" /* webpackChunkName: "component---src-pages-все-о-садике-index-jsx" */),
  "component---src-pages-все-о-садике-распорядок-дня-index-jsx": () => import("./../../../src/pages/все-о-садике/распорядок-дня/index.jsx" /* webpackChunkName: "component---src-pages-все-о-садике-распорядок-дня-index-jsx" */),
  "component---src-pages-все-о-садике-условие-приема-index-jsx": () => import("./../../../src/pages/все-о-садике/условие-приёма/index.jsx" /* webpackChunkName: "component---src-pages-все-о-садике-условие-приема-index-jsx" */),
  "component---src-pages-галерея-jsx": () => import("./../../../src/pages/галерея.jsx" /* webpackChunkName: "component---src-pages-галерея-jsx" */),
  "component---src-pages-контакты-jsx": () => import("./../../../src/pages/контакты.jsx" /* webpackChunkName: "component---src-pages-контакты-jsx" */)
}

